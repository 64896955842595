.kioskContainer {
    background: url("../../assets/img/bg.png") no-repeat;
    background-size: cover;
    width: 800px;
    height: 1280px;
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: center;
}

.content {
    font-size: 16px;
}

.optionsPageHeader {
    margin-top: 63px;
}
body {
    background-color: #ccc;
    margin: 0px;
    padding: 0px;
}

a,
a:hover {
    text-decoration: none;
}

#logo {
    background: url("../../assets/img/logo.png") no-repeat;
    width: 250px;
    height: 65px;
    background-size: cover;
    position: absolute;
    top: 70px;
    right: 270px;
}

#logoRight {
    background: url("../../assets/img/logo.png") no-repeat;
    width: 250px;
    height: 65px;
    background-size: cover;
    position: absolute;
    top: 70px;
    right: 70px;
}

.splashContent {
    margin-top: 220px;
}

.subContent {
    margin-top: 180px;
    padding-top: 20px;
}

.splashFooter {
    position: absolute;
    bottom: 50px;
    width: 100%;
    color: #002e6c;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.02em;
}

h1 {
    color: #002e6c;
    font-size: 4em;
    line-height: 1em;
}

h2 {
    color: #111;
    font-size: 3.25em;
    line-height: 0.7em;
}

h3 {
    color: #555;
    font-size: 1.7em;
    line-height: 0.1em;
    font-weight: 300;
}

.menuMenuButton {
    background: #002e6c;
    border-radius: 10px;
    padding: 10px 15px 4px 15px;
    color: #fff;
    font-weight: 700;
    font-size: 1.8em;
    margin: 0px auto 0px;
    letter-spacing: -0.03em;
    box-shadow: 2px 2px 0px #ccc;
    margin-bottom: 25px;
    position: absolute;
    top: 70px;
    left: 60px;
}

.menuMenuButton svg {
    margin-right: 11px;
    font-size: 1.5em;
}

.menuMenuButton div {
    float: left;
}

.menuMenuButton div div {
    padding: 1px 7px;
}

.topicLabel {
    background: #fff;
    border: 2px solid #6699cc;
    padding: 5px 11px;
    border-radius: 11px;
    display: inline;
    font-weight: 600;
    color: #002e6c;
    font-size: 1.2em;
    letter-spacing: -0.03em;
}

.bigButton {
    background: #002e6c;
    border-radius: 10px;
    padding: 25px 25px 20px 25px;
    color: #fff;
    font-weight: 700;
    font-size: 2.2em;
    margin: 0px auto 0px;
    letter-spacing: -0.03em;
    box-shadow: 2px 2px 0px #ccc;
    margin-bottom: 25px;
}

.bigButton svg {
    font-size: 2em;
    margin-bottom: 11px;
    margin-top: 2px;
}

.smallButton {
    border-radius: 8px;
    display: inline-block;
    padding: 10px 19px;
    font-weight: 700;
    font-size: 0.7em;
    margin: 15px 0px;
}

.gray {
    background: #363636;
    color: #fff;
}

input.loanForm {
    padding: 20px;
    border: 1px solid #97c0e9;
    color: #002e6c;
    font-size: 25px;
    border-radius: 10px;
    font-weight: 300;
    display: inline;
    margin-top: 28px;
    width: 100%;
}

.spaceLeft {
    margin-left: 8px;
}

.spaceRight {
    margin-right: 8px;
}

.fullWidth {
    display: block;
    width: 90.5%;
}

::placeholder {
    color: #6699cc;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #6699cc;
}

::-ms-input-placeholder {
    color: #6699cc;
}

.formContainer {
    padding: 25px 50px 50px 50px;
}

.disclaimerRow {
    text-align: left;
    padding: 5px 15px;
    font-weight: 600;
    margin-bottom: 7px;
}

.disclaimerRow input {
    display: inline;
}

.submitButton {
    background: #38810b;
    border-radius: 10px;
    padding: 21px 21px;
    color: #fff;
    font-weight: 700;
    font-size: 1.4em;
    margin: 0px auto 0px;
    letter-spacing: -0.03em;
    box-shadow: 2px 2px 0px #ccc;
    margin-bottom: 25px;
    display: block;
    position: relative;
}

.submitButton:hover {
    color: #fff;
}

.backButton {
    background: #38810b;
    border-radius: 10px;
    padding: 21px 21px;
    color: #fff;
    font-weight: 700;
    font-size: 1.4em;
    margin: 0px auto 0px;
    letter-spacing: -0.03em;
    box-shadow: 2px 2px 0px #ccc;
    margin-bottom: 25px;
    display: block;
    position: relative;
}

.backButton:hover {
    color: #fff;
}

.submitButton svg {
    position: absolute;
    right: 20px;
    font-size: 1.8em;
    top: 15px;
}

.backButton svg {
    position: absolute;
    left: 20px;
    font-size: 1.8em;
    top: 15px;
}

.disclaimerRow div {
    float: left;
    display: table-column;
}

.disclaimerContent {
    padding: 0px 5px;
}

input.disclaimerCheckbox {
    padding: 5px;
    transform: scale(2);
    margin-right: 10px;
}
.smallerHeadline {
    font-size: 1.9em;
    margin-top: 55px;
}

.consumerAppStepHeader {
    background: #002e6c;
    color: #fff;
    font-size: 22px;
    padding: 10px;
}

label {
    font-size: 1.3em;
}

.left {
    text-align: left;
}

.smallerLabel {
    font-size: 0.8em;
}

.noTopMargin {
    margin-top: 0px !important;
}

.approved {
    background: #38810b;
}

#chatAgent {
    background: url("../../assets/img/chat_icon.png") no-repeat;
    width: 80px;
    height: 80px;
    background-size: cover;
    position: absolute;
    top: 20px;
    right: 20px;
}
